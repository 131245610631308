import * as React from "react"

import { cva } from "../cva.ts"
import { withClass, withWrappedChildren } from "../to-wrapped-children.ts"

export const Button = ({ kind, size, ...props }: Button.Props) => (
  <button
    data-ui="Button"
    data-ui-kind={kind}
    data-ui-size={size}
    {...withClass(withWrappedChildren(props), classForButton, { kind, size })}
  />
)

export namespace Button {
  export interface Props extends React.ComponentProps<"button"> {
    type: "button" | "submit"
    kind: "danger" | "ghost" | "outline" | "shaded" | "solid"
    size: "lg" | "md" | "sm"
  }
}

export const classForButton = cva(
  [
    /* Layout */
    "inline-flex gap-1.5 justify-center",

    /* Text */
    "text-sm/4 text-center",

    /* Appearance */
    "cursor-pointer",

    /* Animation */
    "[transition-property:background-color,box-shadow,color,outline] [transition-duration:.15s] [transition-timing-function:ease-in-out]",

    /* Behavior */
    "select-none",

    /* &:disabled */
    "disabled:cursor-not-allowed",

    /* & > [data-text] */
    "[&>[data-text]]:contents",

    /* &:active, &:focus-visible */
    "user-active:[outline:2px_solid_var(--gray-9)] user-active:outline-offset-1",
    "focus-visible:[outline:2px_solid_var(--gray-9)] focus-visible:outline-offset-1",
  ],
  {
    variants: {
      kind: {
        solid: [
          /** Appearance */
          "bg-gray-12 text-gray-1",

          /* &:interactive */
          "user-interactive:bg-gray-11",

          /* &:active */
          "user-active:bg-gray-12",

          /* &:disabled */
          "disabled:bg-gray-3 disabled:text-gray-8",
        ],
        shaded: [
          /** Appearance */
          "bg-gray-3 text-gray-12",

          /* &:interactive */
          "user-interactive:bg-gray-6",

          /* &:active */
          "user-active:bg-gray-3",

          /* &:disabled */
          "disabled:bg-gray-3 disabled:text-gray-8",
        ],
        outline: [
          /** Appearance */
          "bg-background text-gray-12 bshadow bshadow-gray-6",

          /* &:interactive */
          "user-interactive:bg-gray-6 user-interactive:bshadow-gray-7",

          /* &:active */
          "user-active:bg-gray-3 user-active:bshadow-gray-6",

          /* &:disabled */
          "disabled:bg-background disabled:text-gray-8 disabled:bshadow-gray-6",
        ],
        ghost: [
          /** Appearance */
          "bg-transparent text-gray-11",

          /* &:interactive */
          "user-interactive:bg-gray-4",

          /* &:active */
          "user-active:bg-background",

          /* &:disabled */
          "disabled:bg-gray-8",
        ],
        danger: [
          /** Appearance */
          "bg-tomato-2 text-tomato-11 bshadow bshadow-tomato-4",

          /* &:interactive */
          "user-interactive:bg-tomato-4 user-interactive:bshadow-tomato-6",

          /* &:active */
          "user-active:bg-tomato-2 user-active:bshadow-tomato-6",

          /* &:disabled */
          "disabled:bg-tomato-2 disabled:text-tomato-6 disabled:bshadow-tomato-3",
        ],
      },
      size: {
        sm: "p-1.5 hasnt-[>svg:only-child]:px-2 rounded-md",
        md: "p-2.5 hasnt-[>svg:only-child]:px-3 rounded-md",
        lg: "p-4 hasnt-[>svg:only-child]:px-10",
      },
    },
  },
)
